<template>
  <div>
    <div class="frmBlock__layout">
      <textarea
        class="frmBlock__textField frmBlock__textArea"
        :value="option.value.code"
        @input="updateOption($event.target.value)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object
    }
  },
  data() {
    return {};
  },
  methods: {
    // 옵션 Update
    updateOption(value) {
      this.$emit(
        "updateOption",
        this.option.componentOption.key,
        this.option.componentOption.properties[0].key,
        value
      );
    }
  }
};
</script>

<style lang="scss" scoped></style>
